/**
 * Core constants
 */
export const localeMetaTageSelector = 'meta[name="locale-data"]';
export const defaultRobloxLocaleCode = 'en_us';

/**
 * Asian language specific constants
 */
export const asianLocaleList = ['zh-cn', 'zh-tw', 'ko-kr', 'ja-jp'];
export const localeRequiringSpacesList = ['zh-tw'];

/**
 * Roblox custom locale
 */
export const customLocaleMapping = {
  zh_cjv: 'zh_cn'
};
